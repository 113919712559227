import React from 'react';
import BasicTemplate from '../../../template/BasicTemplate/BasicTemplate';

function Error() {
  return (
    <BasicTemplate>
      <BasicTemplate.Body>
        <div id="systemMsg">
          <p>ページにアクセスできません。</p>
        </div>
        <div className="noticeMsg">
          <p>再度、はじめからお試しください。</p>
          <br />
          <p style={{ marginLeft: '5px' }}>下記の可能性があります。</p>
          <ul style={{ listStyleType: 'disc', marginLeft: '22px' }}>
            <li>不正なリクエストである可能性</li>
            <li>
              時間内に登録を完了する事ができなかったため、セッションの有効期限が切れた可能性
            </li>
          </ul>
        </div>
      </BasicTemplate.Body>
    </BasicTemplate>
  );
}

export default Error;
