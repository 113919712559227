import React from 'react';
import './NotFoundPage.css';

function NotFoundPage() {
  document.title = '404 Page Not Found';

  return (
    <div id="container-404">
      <h1>エラーが発生しました</h1>
      <p>ページが見つかりません。</p>
    </div>
  );
}

export default NotFoundPage;
