import stringWidth from 'string-width';
import {
  Q4_2_DROPDOWN_OPTIONS,
  Q7_RADIO_OPTIONS,
  YEARS,
  MONTHS,
  getDaysByMonthAndYear
} from './formData';

const checkPerCharacter = (booleanFunction, value) => {
  let check = true;

  [...value].forEach(item => {
    if (!booleanFunction(item)) {
      check = false;
      return check;
    }
  });

  return check;
};

const isFullWidth = value => stringWidth(value) === 2;

const checkFullWidth = value => {
  return checkPerCharacter(isFullWidth, value);
};

const checkFullWidthEmpty = value => {
  const removedFullWidthSpace = [...value].filter(i => i !== '　');

  if (removedFullWidthSpace.length === 0) {
    return false;
  }

  return true;
};

const checkBothHalfFullWidthEmpty = value => {
  const removedHalfFullWidthSpace = [...value].filter(
    i => i !== ' ' && i !== '　'
  );

  if (removedHalfFullWidthSpace.length === 0) {
    return false;
  }

  return true;
};

const isNumber = value => /^\d+$/.test(value);

const isFullWidthKatakana = value => /^[\u30A1-\u30FC]+$/.test(value);

const isEmailValid = value =>
  //eslint-disable-next-line
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    value
  );

export const FORM_INPUT_VALIDATION = {
  FULL_WIDTH_ONLY: checkFullWidth,
  FULL_WIDTH_EMPTY: checkFullWidthEmpty,
  HALF_FULL_WIDTH_EMPTY: checkBothHalfFullWidthEmpty,
  NUMBER_ONLY: isNumber,
  FULL_WIDTH_KATAKANA_ONLY: isFullWidthKatakana,
  EMAIL_FORMAT_VALID: isEmailValid
};

const validatePreviousAnswer = (answer, questionId, listOfValidAnswers) => {
  const idx = answer.findIndex(q => q.question_id === questionId);

  if (idx > -1) {
    const qAnswer = answer[idx].answer;

    const isAnswerNotInEnum =
      qAnswer !== '' && !listOfValidAnswers.includes(qAnswer);

    if (isAnswerNotInEnum) {
      answer[idx].answer = '';
    }
  }

  return answer;
};

const validateQ8DayAnswer = answer => {
  const yearIndex = answer.findIndex(q => q.question_id === 'q8_1');
  const monthIndex = answer.findIndex(q => q.question_id === 'q8_2');
  const dayIndex = answer.findIndex(q => q.question_id === 'q8_3');
  const yearValue = answer[yearIndex]?.answer;
  const monthValue = answer[monthIndex]?.answer;
  const dayValue = answer[dayIndex]?.answer;

  // if year and month has value, validate the day answer
  if (yearValue && monthValue) {
    const listOfValidDays = getDaysByMonthAndYear(monthValue, yearValue);
    const isDayNotInEnum =
      dayValue !== '' && !listOfValidDays.includes(dayValue);

    if (isDayNotInEnum) {
      answer[dayIndex].answer = '';
    }
    return answer;
    // if year or month has no value, reset the day answer
  } else {
    answer[dayIndex].answer = '';
    return answer;
  }
};

export const previousFormValidation = prevAnswers => {
  validatePreviousAnswer(
    prevAnswers,
    'q4_2',
    Q4_2_DROPDOWN_OPTIONS.map(opt => opt.value)
  );

  validatePreviousAnswer(
    prevAnswers,
    'q7',
    Q7_RADIO_OPTIONS.map(opt => opt.value)
  );

  validatePreviousAnswer(prevAnswers, 'q8_1', YEARS);

  validatePreviousAnswer(prevAnswers, 'q8_2', MONTHS);

  validateQ8DayAnswer(prevAnswers);

  return prevAnswers;
};
