import React from 'react';
import BasicTemplate from '../../template/BasicTemplate/BasicTemplate';

function ConsentPage() {
  return (
    <BasicTemplate>
      <BasicTemplate.Body>
        <div id="systemMsg">
          <p className="info_message">
            <strong>
              キャンペーンにご応募いただくには
              <br />
              LINE認証に同意をお願いいたします。
            </strong>
          </p>
          <br />
          <p>以下ボタンより認証画面へ遷移し、同意をお願いいたします。</p>
          <div id="formButton">
            <input
              type="button"
              id="__confirm"
              value="認証する"
              // reloads the page so that it will return to the root path
              onClick={() => location.reload()}
            />
          </div>
        </div>
      </BasicTemplate.Body>
    </BasicTemplate>
  );
}

export default ConsentPage;
