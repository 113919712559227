import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import {
  INPUT_PATH,
  CONFIRMATION_PARAM
} from '../../../../constants/routePath';
import {
  MONTHS,
  YEARS,
  FORM_ERROR_MESSAGES,
  Q4_2_DROPDOWN_OPTIONS,
  getInitialFormData,
  getDaysByMonthAndYear,
  Q1_RADIO_OPTIONS,
  Q8_CHECKBOX_OPTIONS,
  Q9_CHECKBOX_OPTIONS
} from '../../../../utils/formUtils/formData';
import { FORM_INPUT_VALIDATION } from '../../../../utils/formUtils/formValidations';
import {
  useSurveyAnswerContext,
  setSurveyAnswers as setSurveyAnswersContext
} from '../../../../contexts/SurveyAnswerContext';

const initialErrors = {
  q1: '',
  q2_1: '',
  q2_2: '',
  q3_1: '',
  q3_2: '',
  q4_1: '',
  q4_2: '',
  q4_3: '',
  q4_4: '',
  q4_5: '',
  q5: '',
  q6: '',
  q7: '',
  q8_1: '',
  q8_2: '',
  q8_3: '',
  q9: '',
  q10: ''
};

function SurveyForm() {
  yup.addMethod(yup.string, 'fullWidthOnly', function (errorMessage) {
    return this.test(`test-full-width`, errorMessage, value => {
      return FORM_INPUT_VALIDATION.FULL_WIDTH_ONLY(value);
    });
  });

  yup.addMethod(yup.string, 'fullWidthEmpty', function (errorMessage) {
    return this.test(`test-full-width-empty`, errorMessage, value => {
      return FORM_INPUT_VALIDATION.FULL_WIDTH_EMPTY(value);
    });
  });

  yup.addMethod(yup.string, 'halfFullWidthEmpty', function (errorMessage) {
    return this.test(`test-half-full-width-empty`, errorMessage, value => {
      return FORM_INPUT_VALIDATION.HALF_FULL_WIDTH_EMPTY(value);
    });
  });

  yup.addMethod(yup.string, 'fullWidthKatakanaOnly', function (errorMessage) {
    return this.test(`test-full-width-katakana`, errorMessage, value => {
      return FORM_INPUT_VALIDATION.FULL_WIDTH_KATAKANA_ONLY(value);
    });
  });

  yup.addMethod(yup.string, 'numberOnly', function (errorMessage) {
    return this.test(`test-number-only`, errorMessage, value => {
      return FORM_INPUT_VALIDATION.NUMBER_ONLY(value);
    });
  });

  yup.addMethod(yup.string, 'emailFormatValid', function (errorMessage) {
    return this.test(`test-email-format`, errorMessage, value => {
      return FORM_INPUT_VALIDATION.EMAIL_FORMAT_VALID(value);
    });
  });

  yup.addMethod(yup.string, 'emailConfirmation', function (errorMessage) {
    return this.test(`test-email-confirmation`, errorMessage, function (value) {
      return value === getQuestionAnswer('q6');
    });
  });

  const schema = yup.object().shape({
    q1: yup.string().required(FORM_ERROR_MESSAGES.OPTION_NOT_SELECTED),
    q2_1: yup
      .string()
      .required(FORM_ERROR_MESSAGES.ANSWER_NOT_INPUTTED)
      .fullWidthEmpty(FORM_ERROR_MESSAGES.ANSWER_NOT_INPUTTED)
      .fullWidthOnly(FORM_ERROR_MESSAGES.NOT_FULL_WIDTH)
      .max(20, FORM_ERROR_MESSAGES.NUMBER_GT_20),
    q2_2: yup
      .string()
      .required(FORM_ERROR_MESSAGES.ANSWER_NOT_INPUTTED)
      .fullWidthEmpty(FORM_ERROR_MESSAGES.ANSWER_NOT_INPUTTED)
      .fullWidthOnly(FORM_ERROR_MESSAGES.NOT_FULL_WIDTH)
      .max(20, FORM_ERROR_MESSAGES.NUMBER_GT_20),
    q3_1: yup
      .string()
      .required(FORM_ERROR_MESSAGES.ANSWER_NOT_INPUTTED)
      .fullWidthKatakanaOnly(FORM_ERROR_MESSAGES.NOT_FULL_WIDTH_KATAKANA)
      .max(20, FORM_ERROR_MESSAGES.NUMBER_GT_20),
    q3_2: yup
      .string()
      .required(FORM_ERROR_MESSAGES.ANSWER_NOT_INPUTTED)
      .fullWidthKatakanaOnly(FORM_ERROR_MESSAGES.NOT_FULL_WIDTH_KATAKANA)
      .max(20, FORM_ERROR_MESSAGES.NUMBER_GT_20),
    q4_1: yup
      .string()
      .required(FORM_ERROR_MESSAGES.ANSWER_NOT_INPUTTED)
      .numberOnly(FORM_ERROR_MESSAGES.NUMBER_GT_7_OR_INVALID)
      .min(7, FORM_ERROR_MESSAGES.NUMBER_GT_7_OR_INVALID)
      .max(7, FORM_ERROR_MESSAGES.NUMBER_GT_7_OR_INVALID),
    q4_2: yup.string().required(FORM_ERROR_MESSAGES.DEFAULT_VALUE_SELECTED),
    q4_3: yup
      .string()
      .required(FORM_ERROR_MESSAGES.ANSWER_NOT_INPUTTED)
      .halfFullWidthEmpty(FORM_ERROR_MESSAGES.ANSWER_NOT_INPUTTED)
      .max(60, FORM_ERROR_MESSAGES.NUMBER_GT_60),
    q4_4: yup
      .string()
      .required(FORM_ERROR_MESSAGES.ANSWER_NOT_INPUTTED)
      .halfFullWidthEmpty(FORM_ERROR_MESSAGES.ANSWER_NOT_INPUTTED)
      .max(60, FORM_ERROR_MESSAGES.NUMBER_GT_60),
    q4_5: yup.string().max(60, FORM_ERROR_MESSAGES.NUMBER_GT_60),
    q5: yup
      .string()
      .required(FORM_ERROR_MESSAGES.ANSWER_NOT_INPUTTED)
      .numberOnly(FORM_ERROR_MESSAGES.NUMBER_GT_11_OR_INVALID)
      .min(10, FORM_ERROR_MESSAGES.NUMBER_GT_11_OR_INVALID)
      .max(11, FORM_ERROR_MESSAGES.NUMBER_GT_11_OR_INVALID),
    q6: yup
      .string()
      .required(FORM_ERROR_MESSAGES.ANSWER_NOT_INPUTTED)
      .emailFormatValid(FORM_ERROR_MESSAGES.EMAIL_FORMAT_INVALID)
      .max(129, FORM_ERROR_MESSAGES.NUMBER_GT_129),
    q7: yup.string().required(FORM_ERROR_MESSAGES.OPTION_NOT_SELECTED),
    q8_1: yup.string().required(FORM_ERROR_MESSAGES.DEFAULT_VALUE_SELECTED),
    q8_2: yup.string().required(FORM_ERROR_MESSAGES.DEFAULT_VALUE_SELECTED),
    q8_3: yup.string().required(FORM_ERROR_MESSAGES.DEFAULT_VALUE_SELECTED),
    q9: yup.array().min(1, FORM_ERROR_MESSAGES.OPTION_NOT_SELECTED),
    q10: yup.array().min(1, FORM_ERROR_MESSAGES.OPTION_NOT_SELECTED)
  });

  const { surveyAnswers: surveyAnswersContextData } = useSurveyAnswerContext();
  const [surveyAnswers, setSurveyAnswers] = useState(getInitialFormData());
  const [errors, setErrors] = useState(initialErrors);
  const { dispatch: surveyAnswerDispatch } = useSurveyAnswerContext();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    setSurveyAnswers(surveyAnswersContextData.surveyAnswers);
  }, []);

  const handleRadioChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    const newSurveyAnswers = surveyAnswers.map(a => {
      if (a.question_id === name) {
        return { ...a, answer: value };
      }

      return a;
    });

    setSurveyAnswers(newSurveyAnswers);
  };

  const validateField = () => {
    const errorsClone = { ...errors };

    surveyAnswers.forEach(question => {
      const name = question.question_id;
      const value = question.answer;

      try {
        yup.reach(schema, name).validateSync(value);
        errorsClone[name] = null;
      } catch (e) {
        errorsClone[name] = e.errors;
      }
    });
    setErrors(errorsClone);
    return Object.values(errorsClone).every(err => err === null);
  };

  const handleTextChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    const newSurveyAnswers = surveyAnswers.map(a => {
      if (a.question_id === name) {
        return { ...a, answer: value };
      }

      return a;
    });

    setSurveyAnswers(newSurveyAnswers);
  };

  const handleDropdownChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    const newSurveyAnswers = surveyAnswers.map(a => {
      if (a.question_id === name) {
        return { ...a, answer: value };
      }

      return a;
    });

    setSurveyAnswers(newSurveyAnswers);
  };

  const handleCheckboxChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    const checked = e.target.checked;

    const newSurveyAnswers = surveyAnswers.map(a => {
      if (a.question_id === name) {
        const answer = checked
          ? [...a.answer, value]
          : [...a.answer].filter(item => item !== value);

        return { ...a, answer: answer.sort((a, b) => Number(a) - Number(b)) };
      }

      return a;
    });

    setSurveyAnswers(newSurveyAnswers);
  };

  const getQuestionAnswer = questionId => {
    const question = surveyAnswers.find(
      question => questionId === question.question_id
    );
    return question ? question.answer : null;
  };

  const handleSubmit = e => {
    e.preventDefault();
    const isFormValid = validateField();

    if (isFormValid) {
      surveyAnswerDispatch(setSurveyAnswersContext(surveyAnswers));
      navigate(`${INPUT_PATH}${CONFIRMATION_PARAM}`);
    } else {
      window.scrollTo(0, 0);
    }
  };

  const renderErrorMessage = (...questionIds) => {
    let errorQuestionId = null;

    for (let questionId of questionIds) {
      if (errors[questionId]) {
        errorQuestionId = questionId;
        break;
      }
    }

    return errorQuestionId && errors[errorQuestionId] ? (
      <p className="error_message">{errors[errorQuestionId]}</p>
    ) : null;
  };

  return (
    <div className="content">
      <div>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <table className="form">
            <tbody>
              <tr>
                <th className="th">
                  Q1. ご希望商品<span className="p_info p_hissu">必須</span>
                </th>
              </tr>
              <tr>
                <td className="time multi_items">
                  <ul className="multi_radio_list">
                    {Q1_RADIO_OPTIONS.map((option, i) => {
                      const id = `q1_option_${option.value}`;
                      return (
                        <li key={i}>
                          <input
                            type="radio"
                            name="q1"
                            id={id}
                            value={option.value}
                            onChange={handleRadioChange}
                            checked={getQuestionAnswer('q1') === option.value}
                          />
                          <label htmlFor={id} className="pre_line">
                            {option.label}
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                  <span className="example">
                    ※複数の賞品へのご応募はできません
                  </span>
                  {renderErrorMessage('q1')}
                </td>
              </tr>
              <tr>
                <th className="th">
                  {'Q2. お名前　漢字'}
                  <span className="p_info p_hissu">必須</span>
                </th>
              </tr>
              <tr>
                <td className="time oneline multi_items">
                  <ul className="horizontal_ul">
                    <li>
                      <p className="item_name">姓</p>
                      <input
                        type="name"
                        name="q2_1"
                        id="q2_1"
                        maxLength={20}
                        placeholder="明治"
                        onChange={handleTextChange}
                        value={getQuestionAnswer('q2_1')}
                      />
                    </li>
                    <li>
                      <p className="item_name">名</p>
                      <input
                        type="name"
                        name="q2_2"
                        id="q2_2"
                        maxLength={20}
                        placeholder="安"
                        onChange={handleTextChange}
                        value={getQuestionAnswer('q2_2')}
                      />
                    </li>
                  </ul>
                  {renderErrorMessage('q2_1', 'q2_2')}
                </td>
              </tr>

              <tr>
                <th className="th">
                  {'Q3. お名前　フリガナ'}
                  <span className="p_info p_hissu">必須</span>
                </th>
              </tr>
              <tr>
                <td className="time oneline multi_items">
                  <ul className="horizontal_ul">
                    <li>
                      <p className="item_name">セイ</p>
                      <input
                        type="name_kana"
                        name="q3_1"
                        id="q3_1"
                        maxLength={20}
                        placeholder="メイジ"
                        onChange={handleTextChange}
                        value={getQuestionAnswer('q3_1')}
                      />
                    </li>
                    <li>
                      <p className="item_name">メイ</p>
                      <input
                        type="name_kana"
                        name="q3_2"
                        id="q3_2"
                        maxLength={20}
                        placeholder="ヤスシ"
                        onChange={handleTextChange}
                        value={getQuestionAnswer('q3_2')}
                      />
                    </li>
                  </ul>
                  {renderErrorMessage('q3_1', 'q3_2')}
                </td>
              </tr>

              <tr>
                <th className="th">
                  Q4. ご自宅住所<span className="p_info p_hissu">必須</span>
                </th>
              </tr>
              <tr>
                <td>
                  <ul className="horizontal_ul">
                    <li>
                      <p className="item_name">郵便番号</p>
                      <input
                        type="street_address"
                        name="q4_1"
                        id="q4_1"
                        maxLength={7}
                        placeholder="1000005"
                        onChange={handleTextChange}
                        value={getQuestionAnswer('q4_1')}
                        pattern="^[0-9]+$"
                      />
                    </li>
                  </ul>
                  <span className="example">
                    ※7桁の半角数字で入力してください（ハイフン不要）
                  </span>
                  {renderErrorMessage('q4_1')}

                  <ul className="horizontal_ul">
                    <li>
                      <p className="item_name">都道府県</p>
                      <div className="selectWrap">
                        <select
                          name="q4_2"
                          id="q4_2"
                          onChange={handleDropdownChange}
                          value={getQuestionAnswer('q4_2')}
                        >
                          <option value="" disabled="disabled">
                            都道府県
                          </option>
                          {Q4_2_DROPDOWN_OPTIONS.map((option, key) => {
                            return (
                              <option value={option.value} key={key}>
                                {option.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </li>
                  </ul>
                  {renderErrorMessage('q4_2')}

                  <ul className="horizontal_ul">
                    <li>
                      <p className="item_name">市区町村</p>
                      <input
                        type="street_address"
                        name="q4_3"
                        id="q4_3"
                        maxLength={60}
                        placeholder="千代田区"
                        onChange={handleTextChange}
                        value={getQuestionAnswer('q4_3')}
                      />
                    </li>
                  </ul>
                  {renderErrorMessage('q4_3')}

                  <ul className="horizontal_ul">
                    <li>
                      <p className="item_name">番地</p>
                      <input
                        type="street_address"
                        name="q4_4"
                        id="q4_4"
                        maxLength={60}
                        placeholder="丸の内２－１－１"
                        onChange={handleTextChange}
                        value={getQuestionAnswer('q4_4')}
                      />
                    </li>
                  </ul>
                  {renderErrorMessage('q4_4')}

                  <ul className="horizontal_ul">
                    <li>
                      <p className="item_name">建物・部屋番号（任意）</p>
                      <input
                        type="street_address"
                        name="q4_5"
                        id="q4_5"
                        maxLength={60}
                        placeholder="ＭＹビル"
                        onChange={handleTextChange}
                        value={getQuestionAnswer('q4_5')}
                      />
                    </li>
                  </ul>
                  {renderErrorMessage('q4_5')}
                </td>
              </tr>

              <tr>
                <th className="th">
                  Q5. 電話番号<span className="p_info p_hissu">必須</span>
                </th>
              </tr>
              <tr>
                <td>
                  <ul className="horizontal_ul">
                    <li>
                      <input
                        type="tel"
                        name="q5"
                        id="q5"
                        maxLength={11}
                        placeholder="00012345678"
                        onChange={handleTextChange}
                        value={getQuestionAnswer('q5')}
                        pattern="^[0-9]+$"
                      />
                    </li>
                  </ul>
                  <span className="example">
                    ※10桁～11桁の半角数字で入力してください（ハイフン不要）
                  </span>
                  {renderErrorMessage('q5')}
                </td>
              </tr>

              <tr>
                <th className="th">
                  Q6. メールアドレス
                  <span className="p_info p_hissu">必須</span>
                </th>
              </tr>
              <tr>
                <td className="time oneline multi_items">
                  <ul className="horizontal_ul">
                    <li>
                      <input
                        type="email"
                        name="q6"
                        id="q6"
                        maxLength={129}
                        placeholder="y-meiji@meijiyasuda.ne.jp"
                        onChange={handleTextChange}
                        value={getQuestionAnswer('q6')}
                      />
                    </li>
                  </ul>
                  {renderErrorMessage('q6')}
                </td>
              </tr>

              <tr>
                <th className="th">
                  Q7. 性別<span className="p_info p_hissu">必須</span>
                </th>
              </tr>
              <tr>
                <td className="time oneline multi_items">
                  <ul className="multi_radio_list">
                    <li>
                      <input
                        type="radio"
                        name="q7"
                        id="q7_1"
                        value="1"
                        onChange={handleRadioChange}
                        checked={getQuestionAnswer('q7') === '1' ? true : false}
                      />
                      <label htmlFor="q7_1">男性</label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        name="q7"
                        id="q7_2"
                        value="2"
                        onChange={handleRadioChange}
                        checked={getQuestionAnswer('q7') === '2' ? true : false}
                      />
                      <label htmlFor="q7_2">女性</label>
                    </li>
                  </ul>
                  {renderErrorMessage('q7')}
                </td>
              </tr>

              <tr>
                <th className="th">
                  Q8. 生年月日<span className="p_info p_hissu">必須</span>
                </th>
              </tr>
              <tr>
                <td className="time oneline multi_items">
                  <div className="selectWrap horizon_triple">
                    <select
                      name="q8_1"
                      id="q8_1"
                      onChange={handleDropdownChange}
                      value={getQuestionAnswer('q8_1')}
                    >
                      <option value="" disabled="disabled">
                        年
                      </option>
                      {YEARS.map((year, key) => {
                        return (
                          <option value={year} key={key}>
                            {year}
                          </option>
                        );
                      })}
                    </select>
                  </div>{' '}
                  <div className="selectWrap horizon_triple">
                    <select
                      name="q8_2"
                      id="q8_2"
                      onChange={handleDropdownChange}
                      value={getQuestionAnswer('q8_2')}
                    >
                      <option value="" disabled="disabled">
                        月
                      </option>
                      {MONTHS.map((month, key) => {
                        return (
                          <option value={month} key={key}>
                            {month}
                          </option>
                        );
                      })}
                    </select>
                  </div>{' '}
                  <div className="selectWrap horizon_triple">
                    <select
                      name="q8_3"
                      id="q8_3"
                      onChange={handleDropdownChange}
                      value={getQuestionAnswer('q8_3')}
                    >
                      <option value="" disabled="disabled">
                        日
                      </option>
                      {getQuestionAnswer('q8_1') && getQuestionAnswer('q8_2')
                        ? getDaysByMonthAndYear(
                            getQuestionAnswer('q8_2'),
                            getQuestionAnswer('q8_1')
                          ).map((day, key) => {
                            return (
                              <option value={day} key={key}>
                                {day}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                  {renderErrorMessage('q8_1', 'q8_2', 'q8_3')}
                </td>
              </tr>
              <tr>
                <th className="th">
                  Q9.
                  直近1年間でむかえた、あるいは今後予定されているライフイベントについて教えてください（複数回答可）
                  <span className="p_info p_hissu">必須</span>
                </th>
              </tr>
              <tr>
                <td className="time multi_items">
                  <ul>
                    {Q8_CHECKBOX_OPTIONS.map((option, key) => {
                      const id = `q9_option_${option.value}`;
                      return (
                        <li key={key}>
                          <input
                            type="checkbox"
                            name="q9"
                            id={id}
                            value={option.value}
                            onChange={handleCheckboxChange}
                            checked={
                              getQuestionAnswer('q9').indexOf(option.value) > -1
                            }
                          />
                          <label htmlFor={id}>{option.label}</label>
                        </li>
                      );
                    })}
                  </ul>
                  {renderErrorMessage('q9')}
                </td>
              </tr>
              <tr>
                <th className="th">
                  Q10. あなたの興味があることを教えてください（複数回答可）
                  <span className="p_info p_hissu">必須</span>
                </th>
              </tr>
              <tr>
                <td className="time multi_items">
                  <ul>
                    {Q9_CHECKBOX_OPTIONS.map((option, key) => {
                      const id = `q10_option_${option.value}`;
                      return (
                        <li key={key}>
                          <input
                            type="checkbox"
                            name="q10"
                            id={id}
                            value={option.value}
                            onChange={handleCheckboxChange}
                            checked={
                              getQuestionAnswer('q10').indexOf(option.value) >
                              -1
                            }
                          />
                          <label htmlFor={id}>{option.label}</label>
                        </li>
                      );
                    })}
                  </ul>
                  {renderErrorMessage('q10')}
                </td>
              </tr>
            </tbody>
          </table>
          <div id="formButton">
            <input
              type="submit"
              name="__confirm"
              id="__confirm"
              value="規約に同意して送信する"
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default SurveyForm;
