import { getInitialFormData } from './formData';

const mapper = {
  q1: 'e_1',
  q2_1: 'e_2_1',
  q2_2: 'e_2_2',
  q3_1: 'e_3_1',
  q3_2: 'e_3_2',
  q4_1: 'e_4_1',
  q4_2: 'e_4_2',
  q4_3: 'e_4_3',
  q4_4: 'e_4_4',
  q4_5: 'e_4_5',
  q5: 'e_5',
  q6: 'e_6',
  q7: 'e_7',
  q8_1: 'e_8_year',
  q8_2: 'e_8_month',
  q8_3: 'e_8_day',
  q9: 'e_9',
  q10: 'e_10'
};

export const apiDataToSurveyForm = apiData => {
  const initialSurveyAnswers = getInitialFormData();
  const surveyAnswer = initialSurveyAnswers;

  if (apiData) {
    for (const [surveyKey, apiDataKey] of Object.entries(mapper)) {
      const question = surveyAnswer.find(
        item => item.question_id === surveyKey
      );

      if (question && apiData[apiDataKey]) {
        // checkbox
        if (surveyKey === 'q9' || surveyKey === 'q10') {
          question.answer = apiData[apiDataKey].split(',');
        } else {
          question.answer = apiData[apiDataKey].toString();
        }
      }
    }
  }

  return surveyAnswer;
};

export const surveyFormToApiData = surveyAnswers => {
  const typeNumber = ['e_1', 'e_4_2', 'e_7'];
  const apiData = {};
  if (surveyAnswers) {
    for (const [surveyKey, apiDataKey] of Object.entries(mapper)) {
      const question = surveyAnswers.find(
        item => item.question_id === surveyKey
      );

      if (question) {
        let answer = question.answer;

        if (typeNumber.indexOf(apiDataKey) > -1) {
          answer = Number(answer);
        } else if (Array.isArray(answer)) {
          answer = answer.join(',');
        }

        apiData[apiDataKey] = answer;
      }
    }
  }

  return apiData;
};
