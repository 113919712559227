import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

import {
  PARAM_INPUT,
  PARAM_AGREE,
  PARAM_APPLIED,
  PARAM_BLOCK,
  PARAM_COMPLETE,
  PARAM_CONFIRM,
  PARAM_END,
  PARAM_ERROR,
  INPUT_PATH
} from '../constants/routePath';

const tagManagerId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;
const tagEventName = 'meijiyasuda-web-page-view-event';

/**
 * Data Layer values are defined in the Pages sheet.
 * Ref: \45 SI\101 CHF\40 High Level Design\20 UI\MG CHF Pages.xlsx (Page List sheet, Column H & I)
 */
const GTM_DATA_LAYER = {
  [PARAM_INPUT]: {
    page: `${INPUT_PATH}?page=input`,
    pageTitle: '入力画面'
  },
  [PARAM_CONFIRM]: {
    page: `${INPUT_PATH}?page=confirm`,
    pageTitle: '確認画面'
  },
  [PARAM_COMPLETE]: {
    page: `${INPUT_PATH}?page=complete`,
    pageTitle: '完了画面'
  },
  [PARAM_AGREE]: {
    page: `${INPUT_PATH}?page=agree`,
    pageTitle: '同意訴求画面'
  },
  [PARAM_BLOCK]: {
    page: `${INPUT_PATH}?page=block`,
    pageTitle: 'ブロック解除訴求画面'
  },
  [PARAM_APPLIED]: {
    page: `${INPUT_PATH}?page=applied`,
    pageTitle: '応募済み画面'
  },
  [PARAM_END]: {
    page: `${INPUT_PATH}?page=end`,
    pageTitle: '終了画面'
  },
  [PARAM_ERROR]: {
    page: `${INPUT_PATH}?page=error2`,
    pageTitle: 'エラー画面'
  }
};

export const useGTM = pageParam => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: tagManagerId
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

  // run every time there is an update to the page parameter
  useEffect(() => {
    if (pageParam) {
      const dataLayer = GTM_DATA_LAYER[pageParam] ?? null;

      if (dataLayer) {
        TagManager.dataLayer({
          dataLayer: {
            event: tagEventName, // Event name speficied in GTM trigger
            page: dataLayer.page,
            pageTitle: dataLayer.pageTitle
          }
        });
      }
    }
  }, [pageParam]);
};
