import React from 'react';
import { useUserContext } from '../../contexts/UserContext';
import { useLocation } from 'react-router-dom';
import {
  SURVEY_PARAM,
  CONFIRMATION_PARAM,
  COMPLETE_PARAM,
  ALREADY_APPLIED_PARAM,
  CAMPAIGN_END_PARAM,
  ERROR_PARAM
} from '../../constants/routePath';
import {
  Survey,
  Confirmation,
  Complete,
  Error,
  AlreadyApplied,
  CampaignEnd
} from './components';

function InputPage() {
  const { user } = useUserContext();
  const { search: urlParams } = useLocation();

  if (urlParams.includes(CAMPAIGN_END_PARAM)) {
    return <CampaignEnd />;
  }

  if (urlParams.includes(ERROR_PARAM)) {
    return <Error />;
  }

  if (user.uid && user.friendFlag) {
    if (urlParams.includes(ALREADY_APPLIED_PARAM)) {
      return <AlreadyApplied />;
    }
    if (urlParams.includes(COMPLETE_PARAM)) {
      return <Complete />;
    }
    if (urlParams.includes(CONFIRMATION_PARAM)) {
      return <Confirmation />;
    }
    if (urlParams.includes(SURVEY_PARAM)) {
      return <Survey />;
    }
  }

  return null;
}

export default InputPage;
