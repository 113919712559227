import dayjs from 'dayjs';

export const getInitialFormData = () => {
  const INITIAL_SURVEY_FORM = [
    {
      question_id: 'q1',
      answer: ''
    },
    {
      question_id: 'q2_1',
      answer: ''
    },
    {
      question_id: 'q2_2',
      answer: ''
    },
    {
      question_id: 'q3_1',
      answer: ''
    },
    {
      question_id: 'q3_2',
      answer: ''
    },
    {
      question_id: 'q4_1',
      answer: ''
    },
    {
      question_id: 'q4_2',
      answer: ''
    },
    {
      question_id: 'q4_3',
      answer: ''
    },
    {
      question_id: 'q4_4',
      answer: ''
    },
    {
      question_id: 'q4_5',
      answer: ''
    },
    {
      question_id: 'q5',
      answer: ''
    },
    {
      question_id: 'q6',
      answer: ''
    },
    {
      question_id: 'q7',
      answer: ''
    },
    {
      question_id: 'q8_1',
      answer: ''
    },
    {
      question_id: 'q8_2',
      answer: ''
    },
    {
      question_id: 'q8_3',
      answer: ''
    },
    {
      question_id: 'q9',
      answer: []
    },
    {
      question_id: 'q10',
      answer: []
    }
  ];

  return INITIAL_SURVEY_FORM;
};

export const getDaysByMonthAndYear = (monthNumber, yearNumber) => {
  const numberOfDays = dayjs(`${yearNumber}-${monthNumber}-1`).daysInMonth();
  const minDay = 1;

  return Array.apply(null, { length: numberOfDays + 1 })
    .map(Number.call, Number)
    .slice(minDay)
    .map(String);
};

export const MONTHS = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12'
];

export const YEARS = [
  '2023',
  '2022',
  '2021',
  '2020',
  '2019',
  '2018',
  '2017',
  '2016',
  '2015',
  '2014',
  '2013',
  '2012',
  '2011',
  '2010',
  '2009',
  '2008',
  '2007',
  '2006',
  '2005',
  '2004',
  '2003',
  '2002',
  '2001',
  '2000',
  '1999',
  '1998',
  '1997',
  '1996',
  '1995',
  '1994',
  '1993',
  '1992',
  '1991',
  '1990',
  '1989',
  '1988',
  '1987',
  '1986',
  '1985',
  '1984',
  '1983',
  '1982',
  '1981',
  '1980',
  '1979',
  '1978',
  '1977',
  '1976',
  '1975',
  '1974',
  '1973',
  '1972',
  '1971',
  '1970',
  '1969',
  '1968',
  '1967',
  '1966',
  '1965',
  '1964',
  '1963',
  '1962',
  '1961',
  '1960',
  '1959',
  '1958',
  '1957',
  '1956',
  '1955',
  '1954',
  '1953',
  '1952',
  '1951',
  '1950',
  '1949',
  '1948',
  '1947',
  '1946',
  '1945',
  '1944',
  '1943',
  '1942',
  '1941',
  '1940',
  '1939',
  '1938',
  '1937',
  '1936',
  '1935',
  '1934',
  '1933',
  '1932',
  '1931',
  '1930',
  '1929',
  '1928',
  '1927',
  '1926',
  '1925',
  '1924',
  '1923',
  '1922',
  '1921',
  '1920',
  '1919'
];

export const FORM_ERROR_MESSAGES = {
  OPTION_NOT_SELECTED: 'エラー：選択してください。',
  ANSWER_NOT_INPUTTED: 'エラー：入力してください。',
  NOT_FULL_WIDTH: 'エラー: 全角文字で入力してください。',
  NUMBER_GT_20: '文字数が上限（20文字）を超えています。',
  NOT_FULL_WIDTH_KATAKANA: 'エラー: 全角カタカナで入力してください。',
  NUMBER_GT_7_OR_INVALID: 'エラー: ７桁の半角数字で入力してください。',
  DEFAULT_VALUE_SELECTED: 'エラー：選択してください。',
  NUMBER_GT_60: '文字数が上限（60文字）を超えています。',
  NUMBER_GT_11_OR_INVALID: 'エラー: 10~11桁の半角数字で入力してください。',
  EMAIL_FORMAT_INVALID: 'エラー: 正しいメールアドレスを入力してください。',
  NUMBER_GT_129: '文字数が上限（129文字）を超えています。',
  EMAIL_NOT_MATCHED: 'エラー: メールアドレスが一致しません。'
};

export const Q1_RADIO_OPTIONS = [
  { value: '1', label: 'スシローお食事券 1,000円分 50名さま' },
  {
    value: '2',
    label: 'ミスタードーナツ ギフトチケット\n(1,000円) 50名さま'
  },
  {
    value: '3',
    label: 'サーティワン アイスクリーム\n1,000円ギフト券 50名さま'
  },
  { value: '4', label: 'タリーズ デジタルギフト(1,000円) 50名さま' }
];

export const Q4_2_DROPDOWN_OPTIONS = [
  { value: '1', label: '北海道' },
  { value: '2', label: '青森県' },
  { value: '3', label: '岩手県' },
  { value: '4', label: '宮城県' },
  { value: '5', label: '秋田県' },
  { value: '6', label: '山形県' },
  { value: '7', label: '福島県' },
  { value: '8', label: '茨城県' },
  { value: '9', label: '栃木県' },
  { value: '10', label: '群馬県' },
  { value: '11', label: '埼玉県' },
  { value: '12', label: '千葉県' },
  { value: '13', label: '東京都' },
  { value: '14', label: '神奈川県' },
  { value: '15', label: '新潟県' },
  { value: '16', label: '富山県' },
  { value: '17', label: '石川県' },
  { value: '18', label: '福井県' },
  { value: '19', label: '山梨県' },
  { value: '20', label: '長野県' },
  { value: '21', label: '岐阜県' },
  { value: '22', label: '静岡県' },
  { value: '23', label: '愛知県' },
  { value: '24', label: '三重県' },
  { value: '25', label: '滋賀県' },
  { value: '26', label: '京都府' },
  { value: '27', label: '大阪府' },
  { value: '28', label: '兵庫県' },
  { value: '29', label: '奈良県' },
  { value: '30', label: '和歌山県' },
  { value: '31', label: '鳥取県' },
  { value: '32', label: '島根県' },
  { value: '33', label: '岡山県' },
  { value: '34', label: '広島県' },
  { value: '35', label: '山口県' },
  { value: '36', label: '徳島県' },
  { value: '37', label: '香川県' },
  { value: '38', label: '愛媛県' },
  { value: '39', label: '高知県' },
  { value: '40', label: '福岡県' },
  { value: '41', label: '佐賀県' },
  { value: '42', label: '長崎県' },
  { value: '43', label: '熊本県' },
  { value: '44', label: '大分県' },
  { value: '45', label: '宮崎県' },
  { value: '46', label: '鹿児島県' },
  { value: '47', label: '沖縄県' }
];

export const Q7_RADIO_OPTIONS = [
  { value: '1', label: '男性' },
  { value: '2', label: '女性' }
];

export const Q8_CHECKBOX_OPTIONS = [
  { value: '1', label: '就職・転職' },
  { value: '2', label: '結婚' },
  { value: '3', label: '出産（ご自身もしくは配偶者）' },
  { value: '4', label: '子どもの独立' },
  { value: '5', label: '定年退職' },
  { value: '6', label: 'このなかにはない/答えたくない' }
];

export const Q9_CHECKBOX_OPTIONS = [
  { value: '1', label: 'マネー：資産運用' },
  { value: '2', label: 'マネー：節約' },
  { value: '3', label: 'ヘルス：運動' },
  { value: '4', label: 'ヘルス：食事' },
  { value: '5', label: 'ヘルス：病気・ケガ' },
  { value: '6', label: 'ヘルス：医療' },
  { value: '7', label: 'ワーク：就職・転職' },
  { value: '8', label: 'ワーク：仕事・自己啓発' },
  { value: '9', label: 'ライフ：結婚・出産・子育て' },
  { value: '10', label: 'ライフ：住まい' },
  { value: '11', label: 'ライフ：老後' },
  { value: '12', label: 'ライフ：美容' },
  { value: '13', label: 'このなかにはない/答えたくない' }
];
