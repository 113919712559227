import getUidHash from '../utils/uidHash';

export const ROOT_PATH = '/form/11';
export const INPUT_PATH = `${ROOT_PATH}/input`;
export const BLOCK_PATH = `${ROOT_PATH}/block`;
export const CONSENT_PATH = `${ROOT_PATH}/concent`;

export const PARAM_INPUT = 'input';
export const PARAM_CONFIRM = 'confirm';
export const PARAM_COMPLETE = 'complete';
export const PARAM_APPLIED = 'applied';
export const PARAM_END = 'end';
export const PARAM_ERROR = 'error';
export const PARAM_BLOCK = 'block';
export const PARAM_AGREE = 'agree';

export const SURVEY_PARAM = `&page=${PARAM_INPUT}`;
export const CONFIRMATION_PARAM = `?page=${PARAM_CONFIRM}`;
export const COMPLETE_PARAM = `?page=${PARAM_COMPLETE}`;
export const ALREADY_APPLIED_PARAM = `?page=${PARAM_APPLIED}`;
export const CAMPAIGN_END_PARAM = `?page=${PARAM_END}`;
export const ERROR_PARAM = `?page=${PARAM_ERROR}`;
export const BLOCK_PARAM = `?page=${PARAM_BLOCK}`;
export const CONSENT_PARAM = `?page=${PARAM_AGREE}`;

export const ANALYTICS_PARAM = uid => {
  return `?utm_source=line&utm_medium=social&utm_campaign=dtdx&utm_content=cpl20241029&lluh=${getUidHash(
    uid
  )}`;
};
