import React from 'react';
import BasicTemplate from '../../../template/BasicTemplate/BasicTemplate';

function Complete() {
  return (
    <BasicTemplate>
      <BasicTemplate.Body>
        <p className="info_message">
          ご応募ありがとうございました！
          <br />
        </p>
        <p className="info_next_campaign">次回キャンペーンもお楽しみに！</p>
      </BasicTemplate.Body>
    </BasicTemplate>
  );
}

export default Complete;
