import React, { createContext, useReducer, useContext } from 'react';

const UserContext = createContext();

// Initial State

const initialState = {
  uid: null,
  friendFlag: false
};

// Actions
const SET_USER = 'SET_USER';

// Action Creators
export const setUser = user => {
  return { type: SET_USER, user };
};

export const userReducer = (state, { type, user }) => {
  switch (type) {
    case SET_USER:
      return {
        uid: user.uid,
        friendFlag: user.friendFlag
      };
    default:
      return state;
  }
};

const UserProvider = props => {
  const [user, dispatch] = useReducer(userReducer, initialState);
  const userData = { user, dispatch };
  return <UserContext.Provider value={userData} {...props} />;
};

const useUserContext = () => {
  return useContext(UserContext);
};

export { UserProvider, useUserContext };
