import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleChevronDown,
  faCircleChevronUp
} from '@fortawesome/free-solid-svg-icons';

function TermsAndConditions() {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      const tc = document.getElementById('terms-and-conditions') || null;
      if (tc) {
        tc.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [open]);

  return (
    <div className="hidden_box">
      <input
        type="checkbox"
        id="label1"
        checked={open}
        onChange={() => setOpen(!open)}
      />
      <label id="tc_label" htmlFor="label1">
        応募規約
        <FontAwesomeIcon
          icon={open ? faCircleChevronUp : faCircleChevronDown}
        />
      </label>
      <div id="terms-and-conditions" className="hidden_show">
        <p className="req_hd">キャンペーン応募規約</p>
        <p className="req_dtl">
          ＊ご応募は、日本国内に在住し、メールで賞品の受け取りが可能な方に限らせていただきます。
          <br />
          ＊ご応募は、おひとりさま1回限りとさせていただきます。
          <br />
          ＊賞品は、おひとりさま1点限りとさせていただきます。
          <br />
          ＊ご当選された方のメールアドレスなどに不備があり賞品がお送りできない場合は、ご当選を無効とさせていただく場合がございます。
          <br />
          ＊ご当選の権利はほかの人へ譲渡・換金できません。
          <br />
          ＊賞品の仕様が変更となる場合があります。
          <br />
          ＊利用期限は、賞品によって異なります。当選メールに記載の利用期限をご確認ください。利用期限が経過した場合は当社は責任を負いません。
          <br />
          ＊システムメンテナンスにより、ご応募ができない期間もございます。
          <br />
        </p>
        <p className="req_hd">＊個人情報の取り扱いについて</p>
        <p className="req_dtl">
          当社は取得いたしました個人情報を以下の目的で利用させていただきます。
        </p>
        <ul className="req_dtl_ul">
          <li>
            各種保険契約のお引き受け、ご継続・維持管理、保険金・給付金等のお支払い
          </li>
          <li>
            子会社・関連会社・提携会社等を含む各種商品・サービスのご案内・提供、ご契約の維持管理
          </li>
          <li>当社業務に関する情報提供・運営管理、商品・サービスの充実</li>
          <li>その他保険に関連・付随する業務</li>
        </ul>
        <p className="req_dtl">
          なお、上記には、お客さまのウェブサイトの閲覧履歴や、グループ会社等から取得した情報等を分析して、お客さまのニーズにあった各種商品・サービスのご案内・提供（広告配信等）をすることを含みます。
          <br />
          当社におけるお客さまに関する情報の取り扱いについては、ホームページ（
          <a
            href="https://www.meijiyasuda.co.jp?openExternalBrowser=1"
            target="_blank"
            rel="noreferrer"
          >
            https://www.meijiyasuda.co.jp/
          </a>
          ）をご覧ください。
        </p>
        <p className="req_hd">＊Cookieについて</p>
        <p className="req_dtl">
          当サイトでは、コンテンツの一部にCookie等を使用しています。お客さまのブラウザの設定で無効化することができますが、当サイトの内容が正しく表示されないことがありますのでご注意ください。
          <br />
          <br />
          当サイトでは、お客さまへの最適な情報の提供、当サイトのアクセスデータの取得・解析、広告配信のためにCookie情報等を利用しております。
          <br />
          Cookie情報等は個人を特定することはできないため、お客さまのプライバシーを侵害ことはございません。
          <br />
          第三者の事業者が、当サイトを訪問したお客さまのCookie情報等を取得・利用している場合があります。当該事業者が取得したCookie情報等は、当該事業者のプライバシーポリシーに従って取り扱われます。
          <br />
          お客さまは、当該第三者の事業者のウェブサイトのオプトアウトページから、Cookie情報等の広告配信への利用を停止することができます。
          <br />
          <br />
          ※第三者の事業者のサービスとして当社ではGoogleアナリティクスでCookie情報等を取得・解析に使用しています。以下のリンクで、当該事業者が案内するオプトアウトの実施をお願いします。
          <br />
          <a
            href="https://support.google.com/analytics/answer/181881?hl=ja&ref_topic=2919631&openExternalBrowser=1"
            target="_blank"
            rel="noreferrer"
          >
            https://support.google.com/analytics/answer/181881?hl=ja&ref_topic=2919631
          </a>
          <br />
          <br />
          なお、サイトの利用規約については、サイトご利用上のご注意（
          <a
            href="https://www.meijiyasuda.co.jp/attention/index.html?openExternalBrowser=1"
            target="_blank"
            rel="noreferrer"
          >
            https://www.meijiyasuda.co.jp/attention/index.html
          </a>
          ）をご覧ください。
        </p>
        <p className="req_hd">＊メールアドレスの取り扱いについて</p>
        <p className="req_dtl">
          当社は取得いたしましたメールアドレスを、サービス・キャンペーン等のメールマガジン・ダイレクトメッセージ等の配信のため利用させていただきますのであらかじめご了承ください。
          <br />
          なお、お客さまが配信の停止をご希望の場合は、速やかに停止いたします。
        </p>
        <br />
        <p className="req_dtl">【本キャンペーンに関するお問い合わせ先】</p>
        <p className="req_dtl">
          明治安田生命保険相互会社
          <br />
          <a href="mailto:W021109@meijiyasuda.co.jp">
            W021109@meijiyasuda.co.jp
          </a>
        </p>
        <br />
        <p className="req_dtl">
          ご応募にあたり、上記のキャンペーン応募規約に同意いただいたうえでご応募いただいているものとみなします。
        </p>
      </div>
    </div>
  );
}

export default TermsAndConditions;
