import React from 'react';
import PageRoutes from './pages/route';
import { UserProvider } from './contexts/UserContext';
import { SurveyAnswerProvider } from './contexts/SurveyAnswerContext';

function App() {
  return (
    <UserProvider>
      <SurveyAnswerProvider>
        <PageRoutes />
      </SurveyAnswerProvider>
    </UserProvider>
  );
}

export default App;
