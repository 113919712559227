import React, { createContext, useReducer, useContext } from 'react';
import { getInitialFormData } from '../utils/formUtils/formData';

const initialAnswers = getInitialFormData();

const SurveyAnswerContext = createContext();

// Initial State
const initialState = {
  surveyAnswers: initialAnswers
};

// Actions
const SET_SURVEY_ANSWERS = 'SET_SURVEY_ANSWER';

// Action Creators
export const setSurveyAnswers = surveyAnswers => {
  return { type: SET_SURVEY_ANSWERS, surveyAnswers };
};

export const SurveyAnswerReducer = (state, { type, surveyAnswers }) => {
  switch (type) {
    case SET_SURVEY_ANSWERS:
      return {
        surveyAnswers: surveyAnswers
      };
    default:
      return state;
  }
};

const SurveyAnswerProvider = props => {
  const [surveyAnswers, dispatch] = useReducer(
    SurveyAnswerReducer,
    initialState
  );
  const surveyAnswerData = { surveyAnswers, dispatch };
  return <SurveyAnswerContext.Provider value={surveyAnswerData} {...props} />;
};

const useSurveyAnswerContext = () => {
  return useContext(SurveyAnswerContext);
};

export { SurveyAnswerProvider, useSurveyAnswerContext };
