import React from 'react';
import BasicTemplate from '../../../template/BasicTemplate/BasicTemplate';
import { getParsedDateInJpFormat } from '../../../utils/dateParser';

const endDate = getParsedDateInJpFormat(process.env.REACT_APP_CP_END_DATETIME);

function CampaignEnd() {
  return (
    <BasicTemplate>
      <BasicTemplate.Body>
        <p className="info_message">
          当キャンペーンの応募期間は、
          <br />
          {`${endDate}をもって終了いたしました。`}
          <br />
        </p>
        <p className="info_next_campaign">次回キャンペーンもお楽しみに！</p>
      </BasicTemplate.Body>
    </BasicTemplate>
  );
}

export default CampaignEnd;
