import { useEffect } from 'react';
import Treasure from 'td-js-sdk';

export const useTreasureData = pageParam => {
  // run every time there is an update to the page parameter
  useEffect(() => {
    if (pageParam) {
      var database = 'li_mydxpj';
      var table = 'tracking_log';
      var writekey = '192/419bba6b66b10992f8bfbbc7b2f4248214db5f29';
      var cookie_domain = '.meijiyasuda-web.loopass.net';
      var uid_hash_key = 'lluh';
      var uid_hash;
      var params = decodeURIComponent(
        window.location.search.substring(1)
      ).split('&');
      var len = params.length;
      var param;
      for (var i = 0; i < len; i++) {
        param = params[i].split('=');
        if (param[0] === uid_hash_key) {
          if (param.length === 2 && param[1].match(/^[a-z0-9]{40}$/)) {
            document.cookie =
              uid_hash_key +
              '=' +
              param[1] +
              '; domain=' +
              cookie_domain +
              '; max-age=63072000; path=/';
          }
          break;
        }
      }
      params = document.cookie.split(';');
      len = params.length;
      param;
      for (let i = 0; i < len; i++) {
        param = params[i].split('=');
        if (param[0].toString().trim() === uid_hash_key) {
          var value = param[1].toString().trim();
          if (param.length === 2 && value.match(/^[a-z0-9]{40}$/)) {
            uid_hash = value;
          }
          break;
        }
      }
      var td = new Treasure({
        host: 'tokyo.in.treasuredata.com',
        writeKey: writekey,
        database: database
      });
      td.set('$global', 'td_global_id', 'td_global_id');
      td.set(table, { lps_uid_hash: uid_hash });
      (function (add_data) {
        td.trackEvent(
          table,
          add_data instanceof Object && !(add_data instanceof Array)
            ? add_data
            : {}
        );
      })();
    }
  }, [pageParam]);
};
