import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import InputPage from './InputPage/InputPage';
import ConsentPage from './ConsentPage';
import AddFriendPage from './AddFriendPage';
import NotFoundPage from './NotFoundPage/NotFoundPage';
import ClipLoader from 'react-spinners/ClipLoader';
import dummyUserProfile from '../devfiles/dummyUserProfile';

import {
  INPUT_PATH,
  CONSENT_PATH,
  BLOCK_PATH,
  ANALYTICS_PARAM,
  SURVEY_PARAM,
  ROOT_PATH,
  CONSENT_PARAM,
  BLOCK_PARAM,
  CAMPAIGN_END_PARAM,
  ALREADY_APPLIED_PARAM,
  ERROR_PARAM
} from '../constants/routePath';
import { useUserContext, setUser } from '../contexts/UserContext';
import {
  useSurveyAnswerContext,
  setSurveyAnswers
} from '../contexts/SurveyAnswerContext';
import { lineLogin } from '../line/lineLogin';
import { apiDataToSurveyForm } from '../utils/formUtils/formMapper';
import { useTreasureData } from '../hooks/treasureData';
import { useGTM } from '../hooks/gtm';
import { useUserProfile, useGetSurvey } from '../hooks/requests';
import { isCampaignEnded } from '../constants/campaignEnd';
import { previousFormValidation } from '../utils/formUtils/formValidations';

const isLocalEnvironment = process.env.REACT_APP_PROFILE === 'local';

function PrivateRoute({ children }) {
  const { user, dispatch: userDispatch } = useUserContext();
  const { dispatch: surveyAnswerDispatch } = useSurveyAnswerContext();
  const {
    callGetSurvey,
    isLoading: isGetSurveyLoading,
    isError: isGetSurveyError
  } = useGetSurvey();
  const {
    callUserProfileApi,
    isLoading: isGetUserLoading,
    isError: isGetUserError
  } = useUserProfile();
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const page = params.get('page');
  const lineLoginCode = params.get('code');

  useTreasureData(page);
  useGTM(page);

  // during initial load, check if user is logged in
  useEffect(() => {
    const initialize = async () => {
      // check campaign end date as priority
      if (isCampaignEnded()) {
        navigate(`${INPUT_PATH}${CAMPAIGN_END_PARAM}`);
      } else {
        if (user.uid) {
          // do nothing if user already successfully logged in
        } else if (lineLoginCode) {
          // if user just logged in we need to call CHFA01 to get the uid and friend status
          // use dummy data for local environment. Calling CHFA01 on local will cause an error due to LINE setting.
          const userProfile = isLocalEnvironment
            ? dummyUserProfile
            : await callUserProfileApi(lineLoginCode);

          if (userProfile) {
            userDispatch(
              setUser({
                uid: userProfile.userId,
                friendFlag: userProfile.friendFlag
              })
            );

            if (userProfile.friendFlag) {
              // condition here to check if user already applied or not.
              const userSurveyInfo = await callGetSurvey(userProfile.userId);

              if (userSurveyInfo) {
                if (userSurveyInfo.alreadyApplied) {
                  navigate(`${INPUT_PATH}${ALREADY_APPLIED_PARAM}`, {
                    replace: true
                  });
                } else {
                  const surveyAnswers = apiDataToSurveyForm(
                    userSurveyInfo.data
                  );
                  surveyAnswerDispatch(
                    setSurveyAnswers(previousFormValidation(surveyAnswers))
                  );
                  navigate(
                    `${INPUT_PATH}${ANALYTICS_PARAM(
                      userProfile.userId
                    )}${SURVEY_PARAM}`,
                    { replace: true }
                  );
                }
              }
            } else {
              navigate(`${BLOCK_PATH}${BLOCK_PARAM}`, { replace: true });
            }
          }
        } else if (params.get('error')?.toUpperCase() === 'ACCESS_DENIED') {
          // if user did not accept the LINE permission, return the consent page
          navigate(`${CONSENT_PATH}${CONSENT_PARAM}`);
        } else {
          lineLogin();
        }
      }
    };

    initialize();
  }, []);

  useEffect(() => {
    if (user.uid) {
      if (lineLoginCode) lineLogin();
    }
  }, [location]);

  // if API throws an error, show error page
  useEffect(() => {
    if (isGetSurveyError || isGetUserError) {
      navigate(`${INPUT_PATH}${ERROR_PARAM}`);
    }
  }, [isGetSurveyError, isGetUserError]);

  return (
    <>
      <ClipLoader
        loading={isGetUserLoading || isGetSurveyLoading}
        cssOverride={{ display: 'block', margin: '20px auto' }}
      />
      {children}
    </>
  );
}

function PageRoute() {
  return (
    <Routes>
      <Route
        path={ROOT_PATH}
        element={
          <PrivateRoute>
            <InputPage />
          </PrivateRoute>
        }
      />
      <Route
        path={INPUT_PATH}
        element={
          <PrivateRoute>
            <InputPage />
          </PrivateRoute>
        }
      />
      <Route
        path={CONSENT_PATH}
        element={
          <PrivateRoute>
            <ConsentPage />
          </PrivateRoute>
        }
      />
      <Route
        path={BLOCK_PATH}
        element={
          <PrivateRoute>
            <AddFriendPage />
          </PrivateRoute>
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default PageRoute;
