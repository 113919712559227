import React from 'react';
import './BasicTemplate.css';

function Header() {
  return (
    <header>
      <img src="/header.png" alt="明治安田生命アンケートフォーム" />
    </header>
  );
}

function Body({ children }) {
  return <div className="container whopper">{children}</div>;
}

function Footer() {
  return (
    <>
      <p className="connum">キ2403600ダイマ推</p>
      <footer>
        <p className="copyright">
          Copyright Meiji Yasuda Life Insurance Company All Rights Reserved.
        </p>
      </footer>
    </>
  );
}

function BasicTemplate({ children }) {
  return (
    <div className="content">
      <Header />
      {children}
      <Footer />
    </div>
  );
}

BasicTemplate.Body = Body;

export default BasicTemplate;
