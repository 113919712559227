import { useState } from 'react';
import { getSurvey, getUserProfile, submitSurvey } from '../api';

export const useUserProfile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const callUserProfileApi = async code => {
    try {
      setIsLoading(true);
      const data = await getUserProfile(code);
      return data;
    } catch (e) {
      console.error(e);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, isError, callUserProfileApi };
};

export const useGetSurvey = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const callGetSurvey = async uid => {
    try {
      setIsLoading(true);
      const data = await getSurvey(uid);
      return data;
    } catch (e) {
      console.error(e);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, isError, callGetSurvey };
};

export const useSubmitSurvey = () => {
  const [isLoading, setIsLoading] = useState(false);

  const callSubmitSurvey = async body => {
    try {
      setIsLoading(true);
      await submitSurvey(body);
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, callSubmitSurvey };
};
