import querystring from 'querystring';
import { LINE_AUTH_URL } from '../constants/lineApiPath';
import cryptoRandomString from 'crypto-random-string';

const clientID = process.env.REACT_APP_CLIENT_ID;
const redirectURI = process.env.REACT_APP_LINE_LOGIN_REDIRECT_URL;

export const lineLogin = () => {
  // Build query string.
  const query = querystring.stringify({
    response_type: 'code',
    client_id: clientID,
    state: cryptoRandomString({ length: 10 }),
    scope: 'profile',
    bot_prompt: 'normal'
  });
  // Build the Line authorise URL.
  const lineAuthoriseURL = `${LINE_AUTH_URL}?${query}&redirect_uri=${redirectURI}`;
  // Redirect to external URL.
  window.location.href = lineAuthoriseURL;
};
