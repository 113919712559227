import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timeZone from 'dayjs/plugin/timezone';
import localeData from 'dayjs/plugin/localeData';
import 'dayjs/locale/ja';

export const JST = {
  TIMEZONE: 'Asia/Tokyo',
  DATE_FORMAT: 'YYYY-MM-DDTHH:mm:ss'
};

export const JP_TEXT = {
  TIMEZONE: 'Asia/Tokyo',
  DATE_FORMAT: 'YYYY年MM月DD日（dd）'
};

dayjs.locale('ja');
dayjs.extend(utc);
dayjs.extend(timeZone);
dayjs.extend(localeData);

const parseDate = stringDate => {
  return Date.parse(stringDate);
};

export const getParsedDateInJst = date => {
  return parseDate(dayjs(date).tz(JST.TIMEZONE).format(JST.DATE_FORMAT));
};

export const getParsedDateInJpFormat = date => {
  return dayjs(date)
    .tz(JP_TEXT.TIMEZONE)
    .format(JP_TEXT.DATE_FORMAT)
    .toString();
};
