import React from 'react';
import BasicTemplate from '../../../template/BasicTemplate/BasicTemplate';
import TermsAndConditions from './SurveyForm/TermsAndConditions';

function AlreadyApplied() {
  return (
    <BasicTemplate>
      <BasicTemplate.Body>
        <p className="info_message">既にご応募済みでございます。</p>
        <p className="info_next_campaign">次回キャンペーンもお楽しみに！</p>
        <TermsAndConditions />
      </BasicTemplate.Body>
    </BasicTemplate>
  );
}

export default AlreadyApplied;
