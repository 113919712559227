import React from 'react';
import BasicTemplate from '../../../template/BasicTemplate/BasicTemplate';
import SurveyForm from './SurveyForm/SurveyForm';
import TermsAndConditions from './SurveyForm/TermsAndConditions';
function Survey() {
  return (
    <BasicTemplate>
      <BasicTemplate.Body>
        <SurveyForm />
      </BasicTemplate.Body>
      <TermsAndConditions />
    </BasicTemplate>
  );
}

export default Survey;
