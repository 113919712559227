import React from 'react';
import BasicTemplate from '../../template/BasicTemplate/BasicTemplate';
import { useUserContext } from '../../contexts/UserContext';

function AddFriendPage() {
  const { user } = useUserContext();
  const botLink = `https://line.me/R/ti/p/${process.env.REACT_APP_BOT_BASIC_ID}`;

  if (user.uid) {
    return (
      <BasicTemplate>
        <BasicTemplate.Body>
          <div id="systemMsg">
            <p className="info_message">
              <strong>
                キャンペーンにご応募いただくには
                <br />
                ブロックの解除をお願いします。
              </strong>
            </p>
            <br />
            <p>以下ボタンより友だち追加をして、再度アクセスしてください。</p>
            <div id="formButton">
              <input
                type="button"
                id="__confirm"
                value="友だち追加する"
                onClick={() => {
                  window.location.href = botLink;
                }}
              />
            </div>
          </div>
        </BasicTemplate.Body>
      </BasicTemplate>
    );
  }

  return null;
}

export default AddFriendPage;
