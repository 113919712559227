import axios from 'axios';
import { HTTP_STATUS_CODE } from './constants/statusCode';

const CP_API_URL = process.env.REACT_APP_CP_API_URL;
const CP_API_KEY = process.env.REACT_APP_CP_API_KEY;

const { BAD_REQUEST, INTERNAL_SERVER, CONFLICT } = HTTP_STATUS_CODE;

/** CP API instance
 *  - Set CP API URL from env variable (CP_API_URL)
 */
const cpApi = axios.create();
cpApi.defaults.baseURL = CP_API_URL;
cpApi.interceptors.request.use(config => {
  config.headers['x-api-key'] = CP_API_KEY;
  return config;
});
/** End */

const apiInstance = {
  axiosInstance: axios,
  endpoint: '',
  [BAD_REQUEST]: BAD_REQUEST,
  [INTERNAL_SERVER]: INTERNAL_SERVER,
  [CONFLICT]: CONFLICT,
  get: function () {
    return this.axiosInstance
      .get(this.endpoint)
      .then(res => res.data)
      .catch(e => this.errorHandling(e));
  },
  post: function (body) {
    return this.axiosInstance
      .post(this.endpoint, body)
      .then(res => res.data)
      .catch(e => this.errorHandling(e));
  },
  errorHandling: function (e) {
    const { response } = e;
    if (response !== undefined) {
      switch (response.status) {
        case BAD_REQUEST:
          throw this[BAD_REQUEST];
        case INTERNAL_SERVER:
          throw this[INTERNAL_SERVER];
        case CONFLICT:
          throw this[CONFLICT];
        default:
          throw e;
      }
    } else {
      throw e;
    }
  }
};

const cpApiInstance = { ...apiInstance };
cpApiInstance.axiosInstance = cpApi;

export const getSurvey = userId => {
  const instance = { ...cpApiInstance };
  instance.endpoint = `/survey?uid=${userId}`;
  return instance.get();
};

export const submitSurvey = requestBody => {
  const instance = { ...cpApiInstance };
  instance.endpoint = '/survey';
  return instance.post(requestBody);
};

export const getUserProfile = code => {
  const instance = { ...cpApiInstance };
  instance.endpoint = `/user?code=${code}`;
  return instance.get();
};
