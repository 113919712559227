import React, { useEffect, useState } from 'react';
import BasicTemplate from '../../../template/BasicTemplate/BasicTemplate';
import { useNavigate } from 'react-router-dom';
import {
  INPUT_PATH,
  COMPLETE_PARAM,
  ANALYTICS_PARAM,
  SURVEY_PARAM,
  CAMPAIGN_END_PARAM
} from '../../../constants/routePath';
import { useSurveyAnswerContext } from '../../../contexts/SurveyAnswerContext';
import {
  Q1_RADIO_OPTIONS,
  Q8_CHECKBOX_OPTIONS,
  Q9_CHECKBOX_OPTIONS,
  Q4_2_DROPDOWN_OPTIONS,
  Q7_RADIO_OPTIONS,
  getInitialFormData
} from '../../../utils/formUtils/formData';
import {
  ERROR_PARAM,
  ALREADY_APPLIED_PARAM
} from '../../../constants/routePath';
import { surveyFormToApiData } from '../../../utils/formUtils/formMapper';
import { useUserContext } from '../../../contexts/UserContext';
import { useSubmitSurvey } from '../../../hooks/requests';
import { HTTP_STATUS_CODE } from '../../../constants/statusCode';
import { isCampaignEnded } from '../../../constants/campaignEnd';

const { CONFLICT } = HTTP_STATUS_CODE;

function Confirmation() {
  const navigate = useNavigate();
  const { surveyAnswers: surveyAnswersContextData } = useSurveyAnswerContext();
  const { user } = useUserContext();
  const [surveyAnswers, setSurveyAnswers] = useState(getInitialFormData());
  const { callSubmitSurvey, isLoading: isSubmitLoading } = useSubmitSurvey();

  useEffect(() => {
    window.scrollTo(0, 0);
    setSurveyAnswers(surveyAnswersContextData.surveyAnswers);
  }, []);

  const getQuestionAnswer = questionId => {
    const question = surveyAnswers.find(
      question => questionId === question.question_id
    );
    return question ? question.answer : null;
  };

  const getOptionQuestionAnswer = (questionId, options) => {
    const question = surveyAnswers.find(
      question => questionId === question.question_id
    );
    const answer = question ? question.answer : null;

    if (answer) {
      const selectedOption = options.find(item => item.value === answer);

      if (selectedOption) {
        return selectedOption.label;
      }
    }

    return null;
  };

  const getCheckboxQuestionAnswer = (questionId, options) => {
    const selectedOptions = [];
    const question = surveyAnswers.find(
      question => questionId === question.question_id
    );
    const answers = question ? question.answer : null;

    if (answers && answers.length > 0) {
      answers.forEach(value => {
        const answer = options.find(item => item.value === value);

        if (answer) {
          selectedOptions.push(answer.label);
        }
      });
    }

    return selectedOptions;
  };

  const handleSubmit = async () => {
    if (isCampaignEnded()) {
      navigate(`${INPUT_PATH}${CAMPAIGN_END_PARAM}`);
    } else {
      const uid = user.uid;
      const answers = surveyFormToApiData(surveyAnswers);

      const requestBody = {
        uid,
        answers
      };

      try {
        await callSubmitSurvey(requestBody);
        navigate(`${INPUT_PATH}${COMPLETE_PARAM}`);
      } catch (e) {
        if (e === CONFLICT) {
          navigate(`${INPUT_PATH}${ALREADY_APPLIED_PARAM}`);
        } else {
          navigate(`${INPUT_PATH}${ERROR_PARAM}`);
        }
      }
    }
  };

  return (
    <BasicTemplate>
      <BasicTemplate.Body>
        <div className="content">
          <div>
            <p className="top_text">
              【ご回答内容 確認画面】
              <br />
              下記の内容でよろしければ「送信する」ボタンを押してください。
            </p>
            {/* <!-- PXX --> */}
            <form>
              <table className="form">
                <tbody>
                  {/* <!-- Q1. ご希望商品 --> */}
                  <tr>
                    <th className="th">Q1. ご希望商品</th>
                  </tr>
                  <tr>
                    <td>{getOptionQuestionAnswer('q1', Q1_RADIO_OPTIONS)}</td>
                  </tr>
                  {/* <!-- Q2. お名前 漢字 --> */}
                  <tr>
                    <th className="th">{'Q2. お名前　漢字'}</th>
                  </tr>
                  <tr>
                    <td>
                      {`${getQuestionAnswer('q2_1')} ${getQuestionAnswer(
                        'q2_2'
                      )}`}
                    </td>
                  </tr>
                  {/* <!-- Q3. お名前 フリガナ --> */}
                  <tr>
                    <th className="th">{'Q3. お名前　フリガナ'}</th>
                  </tr>
                  <tr>
                    <td>{`${getQuestionAnswer('q3_1')} ${getQuestionAnswer(
                      'q3_2'
                    )}`}</td>
                  </tr>
                  {/* <!-- Q4. ご自宅住所 --> */}
                  <tr>
                    <th className="th">Q4. ご自宅住所</th>
                  </tr>
                  <tr>
                    <td>
                      <p className="address_item">郵便番号</p>
                      <div className="address_confirm">
                        {getQuestionAnswer('q4_1')}
                      </div>
                      <p className="address_item">都道府県</p>
                      <div className="address_confirm">
                        {getOptionQuestionAnswer('q4_2', Q4_2_DROPDOWN_OPTIONS)}
                      </div>
                      <p className="address_item">市区町村</p>
                      <div className="address_confirm">
                        {getQuestionAnswer('q4_3')}
                      </div>
                      <p className="address_item">番地</p>
                      <div className="address_confirm">
                        {getQuestionAnswer('q4_4')}
                      </div>
                      <p className="address_item">建物・部屋番号</p>
                      <div className="address_confirm">
                        {getQuestionAnswer('q4_5')}
                      </div>
                    </td>
                  </tr>
                  {/* <!-- Q5. 電話番号 --> */}
                  <tr>
                    <th className="th">Q5. 電話番号</th>
                  </tr>
                  <tr>
                    <td>{getQuestionAnswer('q5')}</td>
                  </tr>
                  {/* <!-- Q6. メールアドレス --> */}
                  <tr>
                    <th className="th">Q6. メールアドレス</th>
                  </tr>
                  <tr>
                    <td>{getQuestionAnswer('q6')}</td>
                  </tr>
                  {/* <!-- Q7. 性別 --> */}
                  <tr>
                    <th className="th">Q7. 性別</th>
                  </tr>
                  <tr>
                    <td>{getOptionQuestionAnswer('q7', Q7_RADIO_OPTIONS)}</td>
                  </tr>
                  {/* <!-- Q8. 生年月日 --> */}
                  <tr>
                    <th className="th">Q8. 生年月日</th>
                  </tr>
                  <tr>
                    <td>
                      {`${getQuestionAnswer('q8_1')}年 ${getQuestionAnswer(
                        'q8_2'
                      )}月 ${getQuestionAnswer('q8_3')}日`}
                    </td>
                  </tr>
                  {/* <!-- Q9. Survey item A --> */}
                  <tr>
                    <th className="th">
                      Q9.
                      直近1年間でむかえた、あるいは今後予定されているライフイベントについて教えてください（複数回答可）
                    </th>
                  </tr>
                  <tr>
                    <td>
                      {getCheckboxQuestionAnswer('q9', Q8_CHECKBOX_OPTIONS).map(
                        (answer, key) => {
                          return (
                            <div key={key}>
                              {answer}
                              <br />
                            </div>
                          );
                        }
                      )}
                    </td>
                  </tr>
                  {/* <!-- Q10. Survey item B --> */}
                  <tr>
                    <th className="th">
                      Q10. あなたの興味があることを教えてください（複数回答可）
                    </th>
                  </tr>
                  <tr>
                    <td>
                      {getCheckboxQuestionAnswer(
                        'q10',
                        Q9_CHECKBOX_OPTIONS
                      ).map((answer, key) => {
                        return (
                          <div key={key}>
                            {answer}
                            <br />
                          </div>
                        );
                      })}
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* <!-- Button --> */}
              <div id="formButton">
                <input
                  type="button"
                  name="__register"
                  id="__register"
                  value="送信する"
                  onClick={handleSubmit}
                  disabled={isSubmitLoading}
                />
                <input
                  type="button"
                  name="__back"
                  id="__back"
                  value="戻る"
                  onClick={() =>
                    navigate(
                      `${INPUT_PATH}${ANALYTICS_PARAM(user.uid)}${SURVEY_PARAM}`
                    )
                  }
                  disabled={isSubmitLoading}
                />
              </div>
            </form>
          </div>
        </div>
      </BasicTemplate.Body>
    </BasicTemplate>
  );
}

export default Confirmation;
