import crypto from 'crypto';

const getUidHash = uid => {
  const envId = 'mydxpj';
  const salt = crypto
    .createHash('sha256')
    .update(uid + envId, 'utf8')
    .digest('hex');
  let hash = uid;
  for (let i = 0; i < 1024; i++) {
    hash = crypto
      .createHash('sha1')
      .update(hash + salt, 'utf8')
      .digest('hex');
  }
  return hash;
};

export default getUidHash;
